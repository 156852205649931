<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    :query="query"
    :requestConfig="{ noTempleFilter: true }"
    entity-name="共修统计"
    hide-action
    show-row-num
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="输入志工姓名查询" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style="width: 180px;"
          placeholder="选择注册小组筛选"
          @change="updateList"
          :options="groupOptions"
          v-model="form.volunteer_group"
          allowClear
          show-search
          option-filter-prop="children"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="form.id_card" placeholder="输入身份证号查询" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-range-picker
          v-model="form.meditation_date"
          :placeholder="['共修开始时间', '共修结束时间']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="updateList"
          class="range-picker"
        />
      </a-form-model-item>
    </template>
    <template v-slot:button>
      <a-button type="primary" @click="exportData" :loading="exporting">
        {{ exporting ? "正在导出" : "批量导出" }}
      </a-button>
    </template>
  </data-list>
</template>

<script>
import { getTemple } from "../../../common/js/storage";
import { getQuery, dataConvertOptions, downLoadBlobFile } from "../../../common/js/tool";

export default {
  name: "CulStatistics",
  data() {
    return {
      url: "/admin/volunteer-meditation-record",
      searchKeyType: {
        meditation_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&filter[${key}][gte]=${value[0]} 00:00:00&filter[${key}][lte]=${value[1]} 23:59:59`;
          }
          return res;
        },
        name: 2,
        volunteer_group: 2,
        id_card: 2,
      },
      columns: [
        {
          title: "姓名",
          customRender: (text) => {
            return <span>{text.volunteer?.name || "-"}</span>;
          },
        },
        {
          title: "身份证号",
          customRender: (text) => {
            return <span>{text.volunteer?.id_card || "-"}</span>;
          },
        },
        {
          title: "注册小组",
          customRender: (text) => {
            return <span>{text.volunteer?.volunteerGroup?.name || "-"}</span>;
          },
        },
        {
          title: "共修类型",
          customRender: (text) => {
            return <span>{text.meditation?.name || "-"}</span>;
          },
        },
        { title: "共修时间", dataIndex: "meditation_date" },
        {
          title: "共修数值",
          customRender: (text) => {
            return (
              <span>
                {text.number || 0}
                {text.meditation?.unit || ""}
              </span>
            );
          },
        },
      ],
      groupOptions: [],
      exporting: false,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    query() {
      let temple = getTemple();
      return `&filter[meditation_record.temple_id]=${temple}&sort=-id&expand=volunteer,meditation`;
    },
  },
  created() {
    this.getGroup();
  },
  methods: {
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let searchKeyType = {
        meditation_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&meditation_start_date=${value[0]}&meditation_end_date=${value[1]}`;
          }
          return res;
        },
        name: 2,
        volunteer_group: 2,
        id_card: 2,
      };
      let url = `/admin/volunteer-meditation-record/export?` + getQuery.call(this.dataList, searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "共修统计数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.status-1 {
  color: @error-color;
}
.status-2 {
  color: @success-color;
}
.ant-calendar-picker {
  text-align: left;
}
</style>
